import { Container, Grid, Typography, Paper, styled, Divider, Box } from '@mui/material'
import React, { useState } from 'react'
import LogoImageOld from './../assets/GiwoTech.png'
import LogoImage from './../assets/giwotech_logo_new.png'
import Human from './../assets/humanonly.png'
import { Link, useNavigate } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';
import { FaFacebookF, FaInstagram, FaGithub} from 'react-icons/fa'
import { FaXTwitter, FaLinkedinIn } from 'react-icons/fa6'

const FoooterGreen = () => {
  const [email, setEmail] = useState("");
  const currentPage = window.location.pathname;
  // alert(currentPage);
  const socialIcons = [{link: 'https://www.twitter.com/@giwotech', icon: FaXTwitter},
                        {link: 'https://www.linkedin.com/company/giwotech/', icon: FaLinkedinIn},
                        // {link: 'http://instagram.com/', icon: FaInstagram},
                        // {link: 'http://instagram.com/', icon: FaGithub}
                      ]
    const Item = styled(Typography)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
        textAlign: 'start',
        color: "#024747",
        // fontWeight: 600,
        fontSize: "16px",
      }));
      
      // backgroundColor: theme.palette.mode === 'dark' ? '#024747' : '#024747',

      // backgroundColor: "#024747",
      let navigate = useNavigate();
      const handleHome = () => {
        if(currentPage  === "/"){
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
        else {
          navigate("/");
        }
      }
      const handleContact =() => {
        navigate("/contact", { state: email});
      }
  return (
    <>
    {/* section fourth */}
    {currentPage === "/"  &&
    <div className="flex flex-col font-semiBoldFont gap-12 items-center mx-auto justify-center p-6 py-44 text-cyan-200">
    <div className="flex justify-start w-full h-auto mb-10 bg-emerald-900 blur-[105px]" />
          <span className="text-[43px] font-boldFont text-center w-3/5">
          Ready to Work at the forefront of <br /> innovation? 
          </span>
          <span className="flex flex-row text-greentext font-defaultFont px-auto justify-center items-center text-[22px] text-center max-w-1/3 w-1/3">
          Powering the world’s best creative teams. From next-gen startups to established enterprises.
          </span>
          <Box sx={{ display: { xs: 'none', md: 'flex', lg: 'flex'}, width: 525, height: 72, position: 'relative'}}>
  <div style={{width: 525, height: 72, left: 0, top: 0, position: 'absolute', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
    <div style={{alignSelf: 'stretch', flex: '1 1 0', paddingTop: 17, paddingBottom: 17, paddingLeft: 25, paddingRight: 16, background: '#011B1B', borderRadius: 15, overflow: 'hidden', border: '0.50px #A1A1AA solid', justifyContent: 'flex-start', alignItems: 'center', gap: 13, display: 'inline-flex'}}>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="focus:outline-none" style={{width: 227, backgroundColor: '#011B1B', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', lineHeight: 26, wordWrap: 'break-word', border: 'none'}} placeholder="Enter email address" />
    </div>
  </div>
  <div style={{width: 197.43, height: 56, padding: 16, left: 318.75, top: 8, position: 'absolute', background: '#024747', borderRadius: 10, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
    <div style={{justifyContent: 'center', alignItems: 'center', gap: 9, display: 'inline-flex'}}>
      <button type="button" onClick={handleContact} style={{textAlign: 'right', color: 'white', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', lineHeight: 24, wordWrap: 'break-word'}}>Learn More</button>
    </div>
  </div>
</Box>
      </div>}
    {/* <Divider dark orientation='middle' sx={{ color: 'green', border: 0.5}}/> */}
    <Typography component={"div"} fullWidth sx={{
        padding: 4,
        // borderRadius: 3,
        backgroundColor: "#011B1B",
        bottom: 8,
        // marginTop: 5,s
        // marginBottom: 3,
        width: '100%',
        // position: 'fixed'
        }} >
      <Grid container spacing={1} className="flex items-center">
        <Grid item xs={12} md={4}>
        {/* <Item> */}
        <div className="flex flex-row items-center cursor-pointer" onClick={handleHome}>
        <img src={Human} className="w-[70px] h-[70px] p-1 mb-2 -mr-4" />
        <img src={LogoImage} style={{ marginLeft: 0, width : "250px", height:"100px", marginRight: "20px"}}/></div>
        {/* </Item>  */}
        </Grid>
        <Grid item xs={6} md={2}>
        <Item>
          <Link className='text-[#93D6D6] font-semiBoldFont' to={'/science'}>Science</Link>
          </Item>  
        </Grid>
        <Grid item xs={6} md={2}>
        <Item><Link className='text-[#93D6D6] font-semiBoldFont' to={'/pipeline'}>Pipeline</Link></Item> 
       
        </Grid>
        <Grid item xs={6} md={2}>
        <Item><Link className='text-[#93D6D6] font-semiBoldFont' to={'/team'}>Team</Link></Item> 
        
          
        </Grid>
        <Grid item xs={6} md={2}>
        <Item><Link className='text-[#93D6D6] font-semiBoldFont' to={'/contact'}>Contact</Link></Item> 
        {/* <Item>
          <a className='text-[#93D6D6] font-defaultFont text-sm' href={'/contact'}>Contact form</a>
          </Item>  */}
        </Grid>
        </Grid>
        <div className='flex flex-row gap-2 ss:pt-10 xs:pt-10 '>
          {socialIcons.map((socialIcon, i) =>(
           <a target="_blank" type="button" className="flex justify-center bg-[#024747]  rounded-full w-[42px] h-[42px] p-3 items-center text-greentext cursor-pointer" href={socialIcon?.link}> {React.createElement(socialIcon?.icon, { size: "20" })} </a>
          ))}
          </div>
        {/* <Divider light sx={{color: "#024747", mt: 2}}/> */}
        <hr height="1px" className=" w-full mt-10" />
        <Typography component={"div"} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', my: '30px'}}>
          <div>
            <p className='text-sm text-greentext'>
            © Copyright 2024, All Rights Reserved by Giwotech
            </p>
          </div>
          <div className='flex flex-row gap-2'>
          {/* {socialIcons.map((socialIcon, i) =>( */}
           <div className='justify-start gap-[38px] inline-flex items-center text-bgdefault'> <span>Terms & Conditions</span> <span>Privacy Policy</span> </div>
          {/* ))} */}
          </div>

        </Typography>
    </Typography>
    </>
  )
}

export default FoooterGreen
