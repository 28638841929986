import React, { useEffect } from "react";
import HeaderSecond from "../../layouts/HeaderSecond";
import Footer from "../../layouts/Footer";
import NSFLogo from "./../../assets/nsf-small-logo-green.png";
import mitVMSLogo from "./../../assets/nerse-small-logo-green.png";
import heroGif from "./../../assets/homepageGif.gif";
import supporter1 from "./../../assets/supporters-1.png";
import splineModel from "./../../assets/spline-model.gif";
import supporter2 from "./../../assets/supporters-2.png";
import supporter3 from "./../../assets/supporters-3n.png";
import supporter4 from "./../../assets/supporters-4.png";
import supporter5 from "./../../assets/supporters-5.png";
import supporter6 from "./../../assets/supporters-6.png";
import supporter7 from "./../../assets/supporters-7.png";
import ScienceImage from "./../../assets/ScienceImage.png";
import ournextstep from "./../../assets/our-next-step.png";
import ourNextStep from "./../../assets/our-nextstep.png";
import backgroundImg from "./../../assets/curvebggreen.png";
import sciencebehindit from "./../../assets/science-behind-it.png";
import { Divider } from "@mui/material";
import HeaderGreen from "../../layouts/HeaderGreen";
import FoooterGreen from "../../layouts/FooterGreen";
import { useNavigate } from "react-router-dom";

const HomeGreen = () => {
  const supporters = [
    { name: "NSF", url: "http://", image: NSFLogo },
    { name: "MIT VMS", url: "http://", image: mitVMSLogo },
  ];
  const supportersList = [
    { name: "NSF", url: "http://", image: supporter1 },
    { name: "MIT VMS", url: "http://", image: supporter2 },
    { name: "NSF", url: "http://", image: supporter3 },
    { name: "MIT VMS", url: "http://", image: supporter4 },
    { name: "The Engine", url: "http://", image: supporter5 },
    // { name: "NE I-Corps", url: "http://", image: supporter6 },
    { name: "Mass Innovation", url: "http://", image: supporter7 },
  ];
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
const navigate = useNavigate();
  return (
    <div className=" bg-greenprimary">
      <HeaderGreen  />
      <div className="w-full flex flex-col p-5 bg-greenprimary py-20">
        <div className="w-full flex md:flex-row lg:flex-row xl:flex-row gap-3 sm:flex-col xs:flex-col ss:flex-col">
          <div className="flex w-2/3 sm:w-full xs:w-full vs:w-full ss:w-full flex-col gap-[31px] px-14 py-20 text-2xl justify-start items-left">
            <h2 className="xl:text-[43px] lg:text-[43px] md:text-[43px] sm:text-[43px] xs:text-[28px] ss:text-[28px] text-[28px] xl:leading-[50px] lg:leading-[50px] md:leading-[50px] sm:leading-[50px] xs:leading-[34px] ss:leading-[34px] font-boldFont text-greentext">
            {/* Creating digital twins from first principles and  sophisticated generative AI simulations for <br/> next-gen therapeutics. */}
              {/* Simulating <br /> Self-assembly of <br /> Biomolecular Systems */}
              {/* A next-gen <span className="bg-gradient-to-r from-indigo-500 via-purple-500 to-transparent -p-2">digital twin</span> platform from first principles and <span className="bg-gradient-to-r from-indigo-500 via-purple-500 to-transparent -p-2">AI/ML</span> simulations for biomedical, plant, and material applications */}
              A next-gen digital twin platform from first principles and AI/ML simulations for biomedical, plant, and material applications
            </h2>
            <div className="flex flex-col gap-3 text-lg justify-start items-start">
              {/* <span>Simulating Self-assembly of Biomolecular Systems</span> */}
              <a
                type="button"
                href="/science"
                className="lg:w-2/5 xl:w-1/5 md:w-2/5 p-2 flex justify-center font-boldFont leading-[24px] rounded-md bg-[#002C2C] text-center px-auto text-white"
              >
                Learn More
              </a>
            </div>
            <div className="flex flex-row gap-3 items-center font-defaultFont font-normal text-[18px] text-greentext">
              <span className="">Supported by </span>
              <img className="" src={NSFLogo} />
              <img className="" src={mitVMSLogo} />
            </div>
          </div>
          
          <div className="xl:flex lg:flex md:flex sm:hidden ss:hidden xs:hidden vs:hidden w-1/3 sm:w-full xs:w-full ss:w-full -mt-10 bg-greenprimary">
          {/* <img src={splineModel}  width='100%' height='100%'/> */}
          <iframe src='https://my.spline.design/clonercubebinarycopy-d555a954b86abfdaf5cc7e5e9f348c93/' frameBorder='0' width='100%' height='120%'></iframe>
            {/* <iframe
              className="w-full"
              src="https://my.spline.design/clonercubebinarycopy-4a77a003e9b27e881fa67f1d072968e9/"
              alt="Science image"
            /> */}
            {/* <img src={heroGif} alt="Science image" /> */}
          </div>
        </div>
      </div>
      {/* section two */}
      <div className="flex flex-col">
        <div
          className="w-full flex flex-col p-20 bg-greenprimary"
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
          }}
        >
          <div className="w-full p-5 flex justify-center items-center md:flex-row lg:flex-row xl:flex-row gap-8 sm:flex-col xs:flex-col ss:flex-col vs:flex-col">
            <div className="flex w-1/2 sm:w-full xs:w-full vs:w-full ss:w-full">
              <img
                src={ScienceImage}
                alt="Science image"
                className="xl:w-[411px] xs:w-[400px] vs:w-[200px]"
              />
            </div>

            <div className="w-full px-[29.87px] py-[26.78px] bg-white bg-opacity-10 rounded-[30.90px] border border-slate-400 backdrop-blur-[41.20px] flex-col justify-start items-start gap-[10.30px] inline-flex">
 <div className="xl:w-[674.59px] lg:w-[674.59px] md:w-[674.59px] sm:w-[674.59px] ss:w-[254.59px] xs:w-[154.59px] vs:w-[120px] ss:text-[24px] sm:text-[24.26px] xs:text-[24px] vs:text-[16px] xl:text-[43.26px] lg:text-[43.26px] md:text-[43.26px] sm:font-extrabold xl:font-extrabold lg:font-extrabold md:font-extrabold ss:font-extrabold xs:font-extrabold vs:font-extrabold sm:leading-[34.85px] xl:leading-[63.85px] lg:leading-[63.85px] md:leading-[63.85px] ss:leading-[40.85px] xs:leading-[40.85px] vs:leading-[20.85px]"><span className="text-[#A7ECEC] ">The </span><span className=" text-[#FF491D]">Science</span><span className="text-cyan-200"> behind it</span></div>
  <div className="xl:w-[629.28px] lg:w-[629.28px] md:w-[629.28px] sm:w-[629.28px] ss:w-[329.28px] xs:w-[229.28px] vs:w-[139.28px] text-neutral-400 xl:text-[22.66px] lg:text-[22.66px] md:text-[22.66px] sm:text-[22.66px] ss:text-[16.66px] xs:text-[16px] vs:text-[14px] font-medium xl:leading-[42.23px] lg:leading-[42.23px] md:leading-[42.23px] sm:leading-[42.23px] ss:leading-[26.23px] xs:leading-[26.23px] vs:leading-[20.23px]">Atomic level dynamic simulations of protein structural interactome provides unconventional grasp on biomolecular systems</div>
  <div className="xl:w-[197.43px] lg:w-[197.43px] md:w-[197.43px] sm:w-[197.43px] ss:w-[197.43px] xs:w-[197.43px] vs:w-[127.43px] h-14 p-4 bg-[#002C2C] rounded-[10px] flex-col justify-center items-center gap-2.5 flex">
    <div className="justify-center items-center gap-[9px] inline-flex">
      <button type="button" className="text-right text-white xl:text-lg lg:text-lg md:text-lg sm:text-lg ss:text-base xs:text-base vs:text-base font-bold leading-normal" onClick={() => navigate('/science')}>Learn More</button>
    </div>
  </div>
</div>
          </div>
          {/* section third */}
          <div className="w-full p-5 flex justify-center items-center md:flex-row lg:flex-row xl:flex-row gap-12 sm:flex-col xs:flex-col vs:flex-col ss:flex-col">
            <div className="w-full px-[29.87px] py-[26.78px] bg-white bg-opacity-10 rounded-[30.90px] border border-slate-400 backdrop-blur-[41.20px] flex-col justify-start items-start gap-[10.30px] inline-flex">
  <div className="xl:w-[674.59px] lg:w-[674.59px] md:w-[674.59px] sm:w-[374.59px] ss:w-[250.59px] xs:w-[274.59px] vs:w-[140px] xl:text-[43.26px] lg:text-[43.26px] md:text-[43.26px] sm:text-[43.26px] ss:text-[24px] xs:text-[24px] vs:text-[14px] font-extrabold  xl:leading-[63.85px] lg:leading-[63.85px] md:leading-[63.85px]"><span className="text-cyan-200 ">Our next</span><span className="text-black"> </span><span className="text-[#FF491D]">steps </span><span className="text-black "></span><span className="text-cyan-200 ">ahead</span></div>
  <div className="xl:w-[629.28px] lg:w-[629.28px] md:w-[629.28px] sm:w-[340.28px] xs:w-[260.28px] vs:w-[140.28px] text-neutral-400 xl:text-[22.66px] lg:text-[22.66px] md:text-[22.66px] sm:text-[22.66px] ss:text-[16px] xs:text-[16px] vs:text-[14px] font-medium xl:leading-[42.23px] lg:leading-[42.23px] md:leading-[42.23px] sm:leading-[42.23px] ss:leading-[24.23px] xs:leading-[24.23px] vs:leading-[20.23px]">Simulating <br /> self-assembly of biomolecular systems</div>
  <div className="xl:w-[197.43px] lg:w-[197.43px] md:w-[197.43px] sm:w-[197.43px] ss:w-[197.43px] xs:w-[197.43px] vs:w-[97.43px] h-14 p-4 bg-[#002C2C] rounded-[10px] flex-col justify-center items-center gap-2.5 flex">
    <div className="justify-center items-center gap-[9px] inline-flex">
      <button type="button" className=" text-right text-white xl:text-lg lg:text-lg md:text-lg sm:text-lg ss:text-lg xs:text-lg vs:text-sm font-bold leading-normal cursor-pointer" onClick={() => navigate('/pipeline')}>Explore</button>
    </div>
  </div>
</div>
            <div className="flex w-2/5 justify-end sm:w-full xs:w-full vs:w-full ss:w-full">
              <img
                src={ourNextStep}
                alt="Science image"
                className="w-[352.536px] h-[337px]"
              />
            </div>
          </div>
      <div className="w-full pb-10 bg-[#132B2F] bg-blur-[8.5px] rounded-[19.16px] shadow-md" >
      {/* <div className="flex justify-center font-boldFont text-[42px] text-cyan-200 font-extrabold leading-[63.85px]"><h2>Supported by</h2></div> */}
      <div className="text-center text-greentext text-[30.92px] font-defaultFont font-normal leading-[107.56px]">Our Supporters </div> 
          <div className="flex flex-wrap gap-10 p-3 mx-auto justify-center items-center px-10">
        {
          supportersList.map((item, index) => (
            <>
            <img className="" src={item.image} alt={item.name} />
            </>

          ))
        }
      </div>
        </div>
        </div>
        
      </div>
      
      {/* footer */}
      {/* <div className=""> */}
        {/* <Divider light sx={{color: "black"}}/> */}
      <FoooterGreen/>
      {/* </div> */}
    </div>
  );
};

export default HomeGreen;
