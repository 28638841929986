import logo from './logo.svg';
import { Navigate, BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material';
import HomePage from './Pages/HomePage/';
import Team from './Pages/Team';
import DefaultLayout from './layouts/DefaultLayout';
import Science from './Pages/Science';
import Pipeline from './Pages/Pipeline';
import Contact from './Pages/Contact';
import HomeNew from './Pages/HomePage/HomeNew';
import HomeUI from './Pages/HomePage/HomeUI';
import HomeGreen from './Pages/HomePage/HomeGreen';
import ContactNew from './Pages/Contact/ContactNew';
import { useEffect } from 'react';

function App() {
  const theme = createTheme({
		palette: {
			primary: {
				main: '#281E23',
			},
			secondary: {
				main: '#F2DBFF',
			},
			accent: {
				main: '#E6E6E6',
			},
			blueText: {
				main: "#4F46E5",
			}
		},
		typography: {
			htmlFontSize: 18,
			color: "#3F3F46",
			fontFamily: [
				'Plus Jakarta Sans',
				'sans-serif',
			].join(','),
		},
	});
	useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  return (
    <ThemeProvider theme={theme}>
		<Router>
      <Routes>
	  {/* <Route path="/" element={<HomePage />} /> */}
	  <Route path="/" element={<HomeGreen />} />
	  <Route path="/homepage" element={<HomeUI />} />
	  <Route path="/home" element={<HomeGreen />} />
	  <Route path="/team" element={<Team />} />
	  <Route path="/science" element={<Science />} />
	  <Route path="/pipeline" element={<Pipeline />} />
	  <Route path="/contact" element={<ContactNew/>} />        
	  <Route path="/contact-new" element={<Contact />} />        
      </Routes>
    </Router>
	{/* <Team /> */}
    </ThemeProvider>
  );
}

export default App;
