import React, { useEffect } from "react";
import HeaderSecond from "../../layouts/HeaderSecond";
import { Box, Paper, Typography } from "@mui/material";
import CustomCard from "./Components/Card";
import profileImage from "./../../assets/image005.png";
import profileImage2 from "./../../assets/image001.png";
import profileImage3 from "./../../assets/image003.png";
import profileImage4 from "./../../assets/image004.png";
import profileImage5 from "./../../assets/image002.png";
import profileImage6 from "./../../assets/jodi.png";
import backgroundImg from "./../../assets/cardFrame.png";
import { FaXTwitter, FaLinkedin } from 'react-icons/fa6'
import HeaderGreen from "../../layouts/HeaderGreen";
import FoooterGreen from "../../layouts/FooterGreen";
// import './Components/FlipCard.css'

const Team = () => {
  
  const TeamMembers = [
    
    {
      name: "Ashwin Lokapally",
      email: "ashwin [at] giwotech.com",
      specialisation: "Machine Learning/AI Scientist",
      profession:
        "Postdoctoral Associate at Northeastern University Fundamental biologist",
      role: "ceo & co-founder",
      link: "https://www.linkedin.com/in/ashwinlokapally",
      image: profileImage2,
      bio: "PhD in molecular biology",
      bio1: "with 10+ years of experience in gene and protein functions."
      // bio: "12+ years of research expertise in genomics, protein function, and neurodevelopment disorders. His postdoc work delved into genetics and structural mechanisms in congenital craniofacial disorders."
      // bio: "“Ashwin is the CEO & Co-founder of GiwoTech. He has over 12years research experience in fundamental biology. His brings in extensive research experience in genomics, protein functional studies, cell and molecular biology. His PhD research was on understanding the role of proteome in neurodevelopmental disorders. As a postdoc he worked on understanding the genetics, structural and functional mechanisms and external factors in decoding congenital craniofacial disorders.”"
      // bio: "“Our leadership team brings together veteran biopharma executives, leading academics, and seasoned entrepreneurs to advance our mission of transforming therapeutic and materials ”"
    },{
      name: "Razvan Marinescu",
      email: "razvan [at] giwotech.com",
      specialisation: "Machine Learning/AI Scientist",
      profession: "Professor of Computer Science at UC Santa Cruz",
      role: "cTo & co-founder",
      link: "https://www.linkedin.com/in/razvan-valentin-marinescu-29b09442/",
      image: profileImage,
      bio: "Professor at UC Santa Cruz",
      bio1: "PhD in computer science with 10+ years of experience in AI/ML."
      // bio: "Professor at UC Santa Cruz specialising in healthcare-focused Machine Learning, focused on  Bayesian generative models for image reconstruction and predictive neurodegenerative disease models during their Ph.D."
      // bio: "“Razvan is the Co-founder and CTO of GiwoTech. He is also an Assistant Professor at UC Santa Cruz. His lab focuses on applications of Machine Learning including causal models and meta learning to healthcare and Medicine. His work involved using Bayesian generative models for natural images which can be used as prior models for image reconstruction tasks. During his PhD, he developed Bayesian statistical models for prediction of Alzheimer’s disease and related neurodegenerative diseases.”"
    },
  ];
  const advisorsTeam = [
    {
      name: "Arthur Hiller",
      email: "",
      specialisation: "Fractional CEO, Amasa & Antylam",
      profession: "Business Advisor and Consultant",
      role: "Business",
      link: "https://www.linkedin.com/in/arthurhiller/",
      image: profileImage3,
      bio: "Formerly SVP",
      bio1: "Millennium Pharma VP,",
      bio2: "Merck & Co. CEO,",
      bio3: "two VC-backed startups",
      // bio: "“Arthur is a biopharma executive currently serving as fractional CEO of two life science companies, formerly SVP of Millennium Pharma and VP at Merck & Co. He is also a faculty at MIT Healthcare Ventures. His more than 35 years of career experience spans across life sciences and healthcare technology industry, in senior leadership roles such as strategy, building commercial operations, launching products, sales management, and corporate development.”",
      updateImg: true,
    },
    
    {
      name: 'Azer Khan', lastname: '',
      email: "",
      specialisation:
        "Professor of Chemistry and Biochemistry, University of Delaware",
      profession: "",
      role: "Business",
      link: "https://www.linkedin.com/in/azerkhan/",
      image: profileImage5, margin: true,
      //  bio: "“Azer Khan is a product manager for the IBM LinuxONE brand. His career spans multiple continents. In the Middle East, he worked on drone surveillance designing use-cases for the oil & gas industry (Aramco and GE) and later at Siemens, US used AI, Analytics and Robotics, designing solutions for the energy and agriculture sector to accelerate adoption of automation and digitization technologies at startups around the world. Azer was also a startup founder, designing consulting services for billboard advertisement clients using AI for ad-targeting and sentiment analysis.”",
       bio: "Senior Product Manager",  
       bio1: "IBM Power Systems",
       bio2: "",
       bio3: "",
    },
  ];
  const advisorsTeam1 = [
    {
      firstname: "Michael Hagan ", lastname: '',
      email: "",
      specialisation: "Machine Learning/AI Scientist",
      profession: "Professor of Physics, Brandeis University",
      role: "Scientific",
      link: "https://www.linkedin.com/in/michael-hagan-a507056/",
      image: profileImage4,
      bio: "Professor of Physics & ",  
      bio1: "Computational Biology",
      bio2: "Brandeis University",
      bio3: "",
      // bio: "“Michael is a professor of Physics and computation biology at Brandeis University. He is one the leading researchers in computational modeling and theory to understand the physical principles that control assembly and dynamical organization in biological, biomimetic, and other soft condensed matter systems. His lab develops and applies computational and theoretical methods along with machine learning tools that bridge disparate length and time scales”",
    },
     {firstname: 'Jodi', lastname:'Hadden-Perilla', email: '', 
     specialisation: 'Fractional CEO, Amasa & Antylam', 
     profession: 'Product Manager, IBM Z and LinuxONE', 
     role: 'Scientific',  link: 'https://www.linkedin.com/in/jodi-hadden-perilla-63445416a/', 
     image: profileImage6,
     bio: "Professor of Chemistry &",   
       bio1: "Biochemistry",
       bio2: "University of Delaware",
       bio3: "",
    //  bio: "“Jodi is a professor of Chemistry and Biochemistry at University of Delaware and a recipient of NSF early career award. Her work leverages the computational microscope to study biological machines, including viruses and molecular motors. Her group dissects machines to reveal the inner-workings of key components and how the components cooperate to drive overall action. By elucidating the mechanisms by which machines function, we aim to identify strategies to inhibit undesired functions, prevent dysfunction, and to engineer new functions.”",
    },
    
  ];
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className=" bg-greenprimary">
      <HeaderGreen />
      <div className="flex flex-col gap-20 justify-center items-center w-full ">
          <div className="text-greentext font-boldFont text-[38px] break-words">
          MEET THE TEAM
          </div>
        </div>
        <div className="flex justify-center items-center text-center mx-auto pb-16 text-greentext font-defaultFont text-[22.6px] w-3/5 break-words" > Our leadership team brings together veteran biopharma executives, leading academics, and seasoned entrepreneurs to advance our mission of transforming therapeutic and materials design to improve human health and quality of life.</div>

      {/* <Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography
            variant="h2"
            component={"div"}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Founding Team
          </Typography>
        </Box>
        <div className="flex flex-row gap-10 mx-20 my-4 justify-center items-center">
          {TeamMembers.map((member) => (
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front border shadow-sm">
                  <div>
                    <img
                      src={member.image}
                      alt="Avatar"
                      style={{ width: "300px", height: "300px" }}
                    />
                  </div>
                </div>
                <div className="flip-card-back">
                  <div className="flex flex-col justify-center items-center my-10">
                    <h1>{member.name}</h1>
                    <p>{member.specialisation}</p>
                    <p>{member.profession}</p>
                    <p>{member.role}</p>
                    <p>{member.email}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Box> */}
      {/* <Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography
            variant="h2"
            component={"div"}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Advisors
          </Typography>
        </Box>
        <div className="flex flex-row gap-10 mx-20 my-4 justify-center items-center">
          {advisorsTeam.map((member) => (
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front border shadow-sm">
                  <div>
                    <img
                      src={member.image}
                      alt="Avatar"
                      style={{ width: "300px", height: "300px" }}
                    />
                  </div>
                </div>
                <div className="flip-card-back">
                  <div className="flex flex-col justify-center items-center my-10">
                    <h1>{member.name}</h1>
                    <p>{member.specialisation}</p>
                    <p>{member.profession}</p>
                    <p>{member.role}</p>
                    <p>{member.email}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Box> */}
<div className="w-full justify-center items-center text-center text-cyan-200 text-[38px] my-4 font-extrabold uppercase leading-[63.85px] tracking-[2.50px]">founding team</div>
      <div className="flex xl:flex-row lg:flex-row md:flex-row sm:flex-col ss:flex-col xs:flex-col vs:flex-col gap-10 mx-20 my-6 justify-center items-center">
        {TeamMembers.map((member) => (
          <div className="group relative rounded-[55px] px-[27px] pt-[27px] hover:rounded-[42px] border-[#9AA3BA] border shadow-md w-[464px] h-[570px]" style={{
          // <div className="group relative rounded-[55px] px-[27px] pt-[27px] hover:rounded-[42px] border-[#9AA3BA] border shadow-md w-[540px] h-[499px]" style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            // width: "00%",
          }}>
            <h1 className="p-2 flex justify-center font-defaultFont text-[#A7ECEC] text-[42px] items-center leading-[63.85px]">
              {member?.name}
            </h1>
            <h1 className="flex justify-center text-greensecondary text-[24px] items-center font-boldFont">
              {(member?.role).toLocaleUpperCase()}
            </h1>
            <div className={`${member?.name === "Ashwin Lokapally" ? "flex justify-end mt-[107px] -mr-[25px]" : "flex justify-end mt-[110px]"}`}>
            <img src={member.image} /> 
            {/* className={` ${member?.name.toLocaleLowerCase() === "Ashwin Lokapally" ? "w-[406px] h-[317px]" : "w-[435px] h-[335px]"}`} */}
 </div>
            <div className="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center font-semiBoldFont bg-[#022A2A] text-[#097777] rounded-[43px]  border-[#9AA3BA] border shadow-md opacity-0 group-hover:h-full group-hover:opacity-100 duration-300 ease-in-out"
            style={{
              backgroundImage: `url(${backgroundImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              // width: "00%",
            }}>
              <div className="flex flex-col justify-start text-center gap-2 items-center">
                {/* <h1 className="text-[#A7ECEC] text-xl">{member.name}</h1>
                <p>{member.specialisation}</p>
                <p>{member.profession}</p>
                <p>{member.role}</p>
                <p>{member.email}</p>
                <div className="flex flex-row gap-2 text-[#079E9E]">
                  <a className="px-1 rounded-full duration-300" href="#">
                    <FaXTwitter size={24} />{" "}
                  </a>
                  <a className="px-1 rounded-full duration-300" href="#">
                    <FaLinkedin size={24} />{" "}
                  </a>
                </div> */}
                <div className="w-full h-[297px] flex-col justify-center items-center inline-flex">
  <div className="text-cyan-200 text-[42px] font-defaultFont leading-[63.85px]">{member?.name}</div>
  <div className="text-teal-700 text-[24px] font-boldFont font-extrabold uppercase leading-[63.85px] tracking-[2.50px]">{member?.role}</div>
  <div className="w-full px-10 items-center text-center text-[#81DADA] text-[18px] font-defaultFont leading-[42.23px]">{member?.bio} <br /> {member?.bio1}</div>
</div>
<div className="w-[81.32px] h-[33.65px] justify-center items-center gap-3.5 inline-flex pt-20">
  {/* <div className="w-[33.65px] h-[33.65px] relative">
    <FaXTwitter className="w-[33.65px] h-[33.65px] left-0 top-0 absolute text-[#9AE5E5]" />
  </div> */}
  <div className="w-[33.65px] h-[33.65px] relative">
  <a href={member?.link} target="_blank"><FaLinkedin className="w-[33.65px] h-[33.65px] left-[-0px] top-0 absolute text-[#9AE5E5]" /></a>
  </div>
</div>
              </div>
            </div>
           
          </div>
        ))}
      </div>
      <div className="w-full justify-center items-center text-center text-cyan-200 text-[38px] my-10 pt-28 font-extrabold uppercase leading-[63.85px] tracking-[2.50px]">Advisors</div>

      <div className="flex xl:flex-row lg:flex-row md:flex-row sm:flex-col ss:flex-col xs:flex-col vs:flex-col gap-10 mx-30 my-6 justify-center items-center">
        {advisorsTeam.map((member) => (
          <div className="group relative rounded-[44px] px-[27px] pt-[27px] ss:mb-20 xs:mb-20 vs:mb-20  hover:rounded-[42px] border-[#9AA3BA] border shadow-md w-[427px] h-[549px]" style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            // width: "00%",
          }}>
            <h1 className="p-2 flex justify-center font-defaultFont text-[#A7ECEC] text-[42px] items-center leading-[63.85px]">
              {member?.name}
            </h1>
            <h1 className="flex justify-center text-greensecondary text-[24px] items-center font-boldFont font-extrabold invisible">
              {(member?.role).toLocaleUpperCase()}
            </h1>
            <div className={`flex justify-end ${member?.name === "Arthur Hiller" ? "mt-[70px] -mr-[25px]" : "-mr-[27px] mt-[38px] rounded-r-2"}`}>
            <img className={` ${member?.name === "Arthur Hiller" ? "rounded-r-[39px]": "rounded-r-[39px]"}`} src={member.image} />
            </div>
            <div className="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center font-semiBoldFont bg-[#022A2A] text-[#097777] rounded-[43px]  border-[#9AA3BA] border shadow-md opacity-0 group-hover:h-full group-hover:opacity-100 duration-300 ease-in-out"
            style={{
              backgroundImage: `url(${backgroundImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              // width: "00%",
            }}>
              <div className="flex flex-col justify-start text-center gap-2 items-center">
                  <div className="w-full flex-col justify-center items-center inline-flex">
  <div className="text-cyan-200 text-[42px] font-defaultFont leading-[63.85px]">{member?.name}</div>
  <div className="text-teal-700 text-[24px] font-boldFont font-extrabold uppercase leading-[63.85px] tracking-[2.50px] invisible">{member?.role}</div>
  <div className="px-6 text-center text-cyan-200 text-lg font-defaultFont leading-[42.23px] ">
    {member?.bio ?? ""} <br/>
    {member?.bio1 ?? ""}<br/>
    {member?.bio2 ?? ""}<br/>
    {member?.bio3 ?? ""}
    </div>
</div>
<div className="w-[81.32px] h-[33.65px] justify-center items-center gap-3.5 inline-flex pt-28">
  {/* <div className="w-[33.65px] h-[33.65px] relative">
    <FaXTwitter className="w-[33.65px] h-[33.65px] left-0 top-0 absolute text-[#9AE5E5]" />
  </div> */}
  <div className="w-[33.65px] h-[33.65px] relative">
  <a href={member?.link} target="_blank"><FaLinkedin className="w-[33.65px] h-[33.65px] left-[-0px] top-0 absolute text-[#9AE5E5]" /></a>
  </div>
</div>
              </div>
            </div>
           
          </div>
        ))}
      </div>
      <div className="flex xl:flex-row lg:flex-row md:flex-row sm:flex-col ss:flex-col xs:flex-col vs:flex-col gap-10 mx-30 mt-[220px] justify-center items-center pb-[300px]">
        {advisorsTeam1.map((member) => (
          <div className="group relative rounded-[44px] px-[27px] pt-[27px] ss:mb-20 xs:mb-20 vs:mb-20 hover:rounded-[42px] border-[#9AA3BA] border shadow-md w-[427px] h-[549px]" style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            // width: "00%",
          }}>
          <div>
            <div className="w-full h-32 flex justify-center text-center items-center">
           <h1 className={`-p-10 p-3 flex justify-center text-center font-defaultFont text-[#A7ECEC] text-[42px] leading-[63.85px] items-center ${member?.margin === true ? '-py-6' : ''}`}>
              {member?.firstname} <br/> {member?.lastname}
            </h1>
            </div>
            </div>
            <h1 className="flex justify-center pt-3 text-greensecondary text-[24px] leading-[63.85px] items-center font-boldFont font-extrabold invisible">
              {(member?.role).toLocaleUpperCase()}
            </h1>
            <div className={`flex justify-end ${member?.firstname === "Jodi" ? "-mr-[27px] -mt-[8px]" : "-mr-[25px] pl-20"}`}>
            <img className={`${member?.firstname === "Jodi" ? "rounded-r-[39px]": "rounded-r-[40px]"}`} src={member.image} />
            </div>
            <div className="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center font-semiBoldFont bg-[#022A2A] text-[#097777] rounded-[43px]  border-[#9AA3BA] border shadow-md opacity-0 group-hover:h-full group-hover:opacity-100 duration-300 ease-in-out"
            style={{
              backgroundImage: `url(${backgroundImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              // width: "00%",
            }}>
              <div className="flex flex-col justify-start text-center gap-2 items-center">
                  <div className="w-full h-[297px] flex-col justify-center items-center inline-flex">
  <div className="text-cyan-200 text-[42px] font-defaultFont leading-[63.85px]">{member?.firstname}<br /> {member?.lastname} </div>
  <div className="text-teal-700 text-[24px] uppercase leading-[63.85px] tracking-[2.50px] font-boldFont font-extrabold invisible">{member?.role}</div>
  <div className="px-6 text-center text-cyan-200 text-lg font-defaultFont leading-[42.23px] ">
  {member?.bio ?? ""} <br/>
    {member?.bio1 ?? ""}<br/>
    {member?.bio2 ?? ""}<br/>
    {/* {member?.bio3 ?? ""} */}
    </div>
</div>
<div className={`w-[81.32px] h-[33.65px] justify-center items-center gap-3.5 inline-flex ${member.firstname === "Jodi" ? "pt-28" : "pt-28"}`}>
  {/* <div className="w-[33.65px] h-[33.65px] relative">
    <FaXTwitter className="w-[33.65px] h-[33.65px] left-0 top-0 absolute text-[#9AE5E5]" />
  </div> */}
  <div className="w-[33.65px] h-[33.65px] relative">
    <a href={member?.link} target="_blank"><FaLinkedin className="w-[33.65px] h-[33.65px] left-[-0px] top-0 absolute text-[#9AE5E5]" /></a>
  </div>
</div>
              </div>
            </div>
           
          </div>
        ))}
      </div>
      <FoooterGreen />
    </div>
  );
};

export default Team;
