import React, {useEffect } from 'react'
import HeaderGreen from '../../layouts/HeaderGreen'
import S1 from './../../assets/emptyframe.png'
import FoooterGreen from '../../layouts/FooterGreen'

const Pipeline = () => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className=" bg-greenprimary">
      <HeaderGreen />

      <div className='pb-10'>
      <div className="flex flex-col gap-[21px] justify-center items-center w-full ">
          <div className="text-greentext uppercase font-boldFont text-[38px] break-words">
          Pipeline
                    </div>
        </div>
        <div className="flex justify-center items-center text-center mx-auto text-greentext font-defaultFont text-[22.6px] w-3/5 break-words" >
        {/* Our leadership team brings together veteran biopharma executives, leading academics, and seasoned entrepreneurs to advance our mission of transforming therapeutic and materials design to improve human health and quality of life.  */}
        </div> 

      </div>
      <div className='mx-auto pb-20'>
      <div className="w-full py-10 flex flex-col justify-center mx-auto px-20 items-center gap-[120px]">
  {/* s1 */}
  <div className="justify-center items-center gap-6 inline-flex w-full"> 
    
    {/* <div className="md:w-2/3 lg:w-[850px] h-[499px] bg-white bg-opacity-0 rounded-[32px] backdrop-blur-[41.20px] flex-col justify-start items-center gap-[10.30px] inline-flex">
      <img className="w-full h-[498px] shadow" src={S1} />
    </div> */}
    <div className="md:w-2/3 lg:w-[850px] h-[499px] bg-white bg-opacity-0 rounded-[32px] backdrop-blur-[41.20px] flex-col justify-start items-center gap-[10.30px] inline-flex">
      <div className="w-full h-full shadow rounded-[32px] border border-[#9AA3BA]" style={{backgroundImage: `url(${S1})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} >
        <span className="flex flex-row justify-center py-48 text-[42px] text-greentext my-auto text-center">Coming Soon....</span>

      </div>
      {/* <div className="w-[749px] h-[246px] left-[-14.15px] absolute opacity-30 bg-cyan-600 rounded-full blur-[62px]" /> */}
      {/* <div className="left-[26.85px] top-[5px] absolute text-[87.51px] font-medium leading-[133.05px]">S2</div> */}
    </div>
  </div>
  
</div>
</div>

<FoooterGreen />
    </div>
  )
}

export default Pipeline
