import React from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'

const HomePage = () => {
  return (
    <div>
      <DefaultLayout />
    </div>
  )
}

export default HomePage
