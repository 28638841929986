import React, { useState, useEffect } from 'react'
import HeaderGreen from '../../layouts/HeaderGreen'
import FooterGreen from '../../layouts/FooterGreen'
import { FaXTwitter, FaLinkedin } from 'react-icons/fa6'
import { IoMdCopy } from 'react-icons/io'
import contactUsImg from './../../assets/contact-us.png'
import contactUsImgSmall from './../../assets/contact-us-new.png'
import { useLocation } from 'react-router-dom'

const ContactNew = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const location = useLocation();
    const emailAddress = location?.state ?? "";
    function onlyNumber(value) {
        return /^[0-9\b]+$/.test(value);
    }
    const socialIcons = [{link: 'https://twitter.com/', icon: FaXTwitter},
    {link: 'http://linkedin.com/', icon: FaLinkedin},
    // {link: 'http://instagram.com/', icon: FaInstagram},
    // {link: 'http://instagram.com/', icon: FaGithub}
  ]
  const copyToClipBoard = async (text) => {
    await navigator.clipboard.writeText(text);
    if(text === 'info@giwotech.com')
    alert("Email copied to clipboard");
  else
  alert("Address copied to clipboard");

  }
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
    <div className='bg-greenprimary'>
      <HeaderGreen />
      <div className=''>
      <section className="text-greensecondary body-font relative pb-[200px] ">
      <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-[38px] leading-[63.85px] uppercase font-boldFont font-extrabold title-font mb-4 text-greentext">
              Contact Us
            </h1>
            <span className="lg:w-2/3 mx-auto font-defaultFont font-normal xl:text-[22.66px] lg:text-[22.66px] md:text-[22.66px]  sm:text-[18.66px] xl:leading-[38.23px] lg:leading-[38.23px] md:leading-[38.23px] sm:leading-[30.23px] text-center">
            Reach out to our team for support, We'd be more than <br/> happy to answer your questions!
            </span>
          </div>
        <div className="container px-5 pt-10 mx-auto">
          
          <div className="container flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-col ss:flex-col xs:flex-col vs:flex-col gap-5">
          <div className="lg:w-1/2 md:w-1/2 items-center">
              <div className="p-2 w-full">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.4308697299116!2d-71.0410499250805!3d42.31200793813793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37afe5ca20589%3A0x882bd63cf20dd1c4!2sVenture%20Development%20Center!5e0!3m2!1sen!2sin!4v1703944075497!5m2!1sen!2sin" style={{ width: "100%", height: "450px", border: "1px solid #09AA3BA", borderRadius: "32px"}}></iframe>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2947.9364860224!2d-71.07138702500141!3d42.36519283477903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e370973c400001%3A0xc0b452e96ac74e9e!2sGiwoTech!5e0!3m2!1sen!2sin!4v1699585229147!5m2!1sen!2sin" style={{ width: "100%", height: "450px", border: "1px solid #09AA3BA", borderRadius: "32px"}}></iframe> */}
                {/* <div className="flex flex-col gap-5">
                  <label
                    for="message"
                    className="leading-7 text-[42px] text-greentext"
                  >
                    Let's Connect
                  </label>
                  <p className="pr-10 mt-4">Reach out to our team for support, We'd be more than happy to answer your questions!</p>
                <img src={contactUsImgSmall} className="w-[250px] flex ml-20 mt-10 justify-center" />
                </div>*/}
              </div>
              <div className="p-2 w-full inline-flex justify-center items-center">

<span className=" text-greentext text-[22.66px] font-defaultFont font-normal pr-3"> Venture Development Center,
UMass Boston, W-3,
100 Morrissey Boulevard,
Boston, MA 02125
</span>
<button type="button" className="text-white p-3 rounded-lg bg-[#002C2C]" onClick={() => copyToClipBoard("Venture Development Center UMass Boston, W-3 100 Morrissey Boulevard Boston, MA 02125")}><IoMdCopy size={24}/></button>
</div>
          </div>
          <div className="flex justify-center lg:w-1/2 md:w-1/2 border-3 rounded-md border-cyan-300">
          
            <div className="flex flex-wrap px-5 ">
              <div className="p-2 w-full">
              {/* <h1 className="sm:text-3xl itemts-center flex justify-center text-2xl font-medium title-font mb-4 text-greentext">
              Contact Us
            </h1> */}
                <div className="relative">
                  {/* <label for="name" className="leading-7 text-lg mb-5 text-greentext">
                    Name
                  </label> */}
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder='Name'
                    className="w-full bg-greenprimary rounded-2xl border text-greentext border-zinc-400 focus:border-border-400 text-base outline-none py-[12px] px-6 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  {/* <label for="name" className="leading-7 text-lg mb-5 text-greentext">
                    Phone Number
                  </label> */}
                  <input
                    type="number"
                    id="name"
                    name="name"
                    className="w-full bg-greenprimary rounded-2xl border text-greentext border-zinc-400 focus:border-border-400 text-base outline-none py-[12px] px-6 leading-8 transition-colors duration-200 ease-in-out"
                    placeholder="Phone Number"
                 />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  {/* <label
                    for="email"
                    className="leading-7 text-lg mb-5 text-greentext"
                  >
                    Email
                  </label> */}
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email "
                    value={emailAddress}
                    className="w-full bg-greenprimary rounded-2xl border border-zinc-400 focus:border-gray-400 text-base outline-none text-greentext py-[12px] px-6 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  {/* <label
                    for="email"
                    className="leading-7 text-lg mb-5 text-greentext"
                  >
                    Email
                  </label> */}
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                    className="w-full bg-greenprimary rounded-2xl border border-zinc-400 focus:border-gray-400 text-base outline-none text-greentext py-[12px] px-6 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  {/* <label
                    for="message"
                    className="leading-7 text-lg mb-5 text-greentext"
                  >
                    Message
                  </label> */}
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Message" rows={5}
                    className="w-full bg-greenprimary rounded-2xl border border-zinc-400 focus:border-gray-400 text-base outline-none text-greentext py-[14px] px-6 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                </div>
              </div>
              <div className="xl:p-2 lg:p-2 md:p-2 sm:p-2 w-full inline-flex justify-between items-center">

                <button type="button" className="flex text-white bg-[#024747] rounded-2xl border-0 xl:py-3 lg:py-3 md:py-3 sm:py-2 ss:py-1 xs:py-1 vs:py-1 xl:px-2 lg:px-8 md:px-8 sm:px-4 ss:px-4 xs:px-4 vs:px-2 focus:outline-none hover:bg-[#024747] xl:text-lg lg:text-lg md:text-lg sm:text-lg ss:text-sm xs:text-sm vs:text-sm">
                  Send Message
                </button>
                <div className="flex flex-row items-center">
                <span className=" text-greentext xl:text-[22.66px] lg:text-[22.66px] md:text-[22.66px] sm:text-[16px] ss:text-[16px] xs:text-[16px] vs:text-[12px] font-defaultFont font-normal pr-3 cursor-pointer">info@giwotech.com</span>
                <button type="button" className="text-white p-3 rounded-lg bg-[#002C2C]" onClick={() => copyToClipBoard("info@giwotech.com")}><IoMdCopy size={20}/></button>
                </div>
              </div>
              
            </div>
          </div>
          </div>
         
        </div>
      </section>
    </div>
    </div>
    
    <FooterGreen />
    </>
  )
}

export default ContactNew
