import React, { useEffect } from 'react'
import HeaderGreen from '../../layouts/HeaderGreen'
import S4 from './../../assets/S4-2.gif'
import S2 from './../../assets/S1-2.gif'
import s3svg from './../../assets/S3svg.svg'
import s2svg from './../../assets/S2svg.svg'
import S3 from './../../assets/S3.gif'
import FoooterGreen from '../../layouts/FooterGreen'

const Science = () => {
  
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className=" bg-greenprimary">
      <HeaderGreen />

      <div className="py-28">
      <div className="flex flex-col gap-21 justify-center items-center w-full ">
          <div className="text-greentext font-boldFont text-[38px] break-words">
          OUR SCIENCE
          </div>
        </div>
        <div className="flex justify-center items-center text-center mx-auto text-greentext font-defaultFont text-[22.6px] w-3/5 break-words" >
        A digital twin platform  from first principles using physics-based atomic-level molecular dynamics and proprietary AI. ‍We target viral proteins to develop first-in-class small molecules and vaccines. </div>

      </div>
      <div className="mx-auto pb-[300px]">
      <div className="w-full py-10 flex flex-col justify-center mx-auto px-20 items-center gap-[120px]">
  {/* s1 */}
  <div className="xl:inline-flex lg:inline-flex md:inline-flex xs:flex-grow sm:flex-grow ss:flex-grow justify-center items-center gap-6  w-full">
  <div className="xl:w-1/3 lg:w-1/3 md:w-1/3 flex-col sm:ml-5 ss:ml-5 xs:ml-5 justify-start items-start gap-6 inline-flex mb-3">
      <div className="w-full h-32 left-0  absolute" />
      <div className="text-greentext xl:text-[28px] lg:text-[28px] md:text-[28px] sm:text-[20px] ss:text-[20px] xs:text-[20px] font-defaultFont font-extrabold uppercase leading-[40.85px] tracking-[1.50px]">ACCURATE FIRST PRINCIPLES DYNAMIC MOLECULAR SIMULATIONS.</div>
      <div className="w-full text-start text-greentext text-[24px] font-defaultFont font-normal leading-[42.23px]">Molecular dynamics simulations provide us information on conformational changes over time.</div>
    </div>
    <div className="md:w-2/3 lg:w-[730px] lg:h-[499px] bg-white bg-opacity-0 rounded-[32px] backdrop-blur-[41.20px] flex-col justify-start items-center gap-[10.30px] inline-flex">
      <img className="w-full shadow rounded-[38px] border border-slate-400" src={S2} />
      {/* <div className="md:w-2/3 lg:w-[743px] lg:h-[599px] opacity-30 bg-cyan-600 rounded-full blur-[22px]" /> */}
      
      {/* <div className="md:w-2/3 lg:w-[743px] lg:h-[499px] bottom-0 left-[-14.15px] absolute opacity-30 bg-cyan-600 rounded-full blur-[22px]" /> */}
    </div>
    
  </div>
  {/* s2 */}
  <div className="xl:inline-flex lg:inline-flex md:inline-flex xs:flex-grow sm:flex-grow ss:flex-grow justify-center items-center gap-6 w-full">
    <div className="md:w-2/3 lg:w-[743px] lg:h-[499px] bg-white bg-opacity-0 rounded-[32px] backdrop-blur-[41.20px] flex-col justify-start items-center gap-[10.30px] inline-flex">
      <img className="w-full shadow rounded-[38px] border border-slate-400" src={S3} />
      {/* <div className="left-[26.85px] top-[20px] pl-3 pt-3 absolute text-[87.51px] text-greenprimary font-medium leading-[133.05px]"><img src={s2svg} /></div> */}
      
      <div className="md:w-2/3 lg:w-[730px] lg:h-[499px] md:h-[499px] opacity-30 bg-cyan-600 rounded-full blur-[62px]" />
      
      {/* <div className="md:w-2/3 lg:w-[743px] h-[99px] bott om-0 left-[-14.15px] absolute opacity-30 bg-cyan-600 rounded-full blur-[62px]" /> */}
    </div>
    <div className="xl:w-1/3 lg:w-1/3 md:w-1/3 flex-col sm:ml-5 ss:ml-5 xs:ml-5 justify-start items-start gap-6 inline-flex sm:mt-5 xs:mt-5 lg:-mt-20 md:-mt-20">
      <div className="w-full h-32 left-0  absolute items-center" />
      <div className="text-greentext xl:text-[28px] lg:text-[28px] md:text-[28px] sm:text-[20px] ss:text-[20px] xs:text-[20px] font-defaultFont font-extrabold uppercase leading-[40.85px] tracking-[1.50px]">ACCELERATED VACCINE AND THERAPEUTIC DISCOVERY</div>
      <div className="w-full text-start text-cyan-200 text-[24px] font-defaultFont font-normal leading-[42.23px]">Our proprietary models can identify compounds and design vaccines targeted at unfamiliar regions with maximum scores.</div>
    </div>
  </div>
  {/* s3 */}
  <div className="xl:inline-flex lg:inline-flex md:inline-flex xs:flex-grow sm:flex-grow ss:flex-grow justify-center items-center gap-6 w-full"> 
    <div className="xl:w-1/3 lg:w-1/3 md:w-1/3 flex-col sm:ml-5 ss:ml-5 xs:ml-5 justify-start items-start gap-6 inline-flex -mt-10">
      <div className="w-full h-32 left-0 absolute items-center" />
      <div className="text-greentext xl:text-[28px] lg:text-[28px] md:text-[28px] sm:text-[20px] ss:text-[20px] xs:text-[20px] font-defaultFont font-extrabold uppercase leading-[40.85px] tracking-[1.50px]">ENRICHED SIMULATIONS THROUGH SOPHISTICATED MACHINE LEARNING.</div>
      <div className="w-full text-start text-greentext text-[24px] font-defaultFont font-normal leading-[42.23px]">High predictive power and accuracy of our physics-based models through active learning,  deep learning and high performance computing.</div>
    </div>
    <div className="md:w-2/3 lg:w-[730px] h-auto p-3 bg-white bg-opacity-0 rounded-[32px] backdrop-blur-[41.20px] flex-col justify-start items-center gap-[10.30px] inline-flex">
      <img className="w-full shadow rounded-[38px] border border-slate-400" src={S4} />
      {/* <div className="left-[26.85px] top-[20px] pl-3 pt-3 absolute text-[87.51px] font-medium leading-[133.05px] text-greenprimary"><img src={s3svg} /></div> */}
      {/* <div className="w-[749px] h-[146px] left-[-14.15px] bottom-0 absolute opacity-30 bg-cyan-600 rounded-full blur-[22px]" /> */}
    </div>
  </div>
</div>
</div>

<FoooterGreen />
    </div>
  )
}

export default Science
