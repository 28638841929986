import { Container, Grid, Typography, Paper, styled, Divider } from '@mui/material'
import React from 'react'
import LogoImage from './../assets/GiwoTech.png'
import { Link } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';
import { FaFacebookF, FaInstagram, FaGithub, FaTwitter } from 'react-icons/fa'

const Footer = () => {
  const socialIcons = [{link: 'https://twitter.com/', icon: FaTwitter},
                        {link: 'http://facebook.com/', icon: FaFacebookF},
                        {link: 'http://instagram.com/', icon: FaInstagram},
                        {link: 'http://instagram.com/', icon: FaGithub}
                      ]
    const Item = styled(Typography)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
        textAlign: 'start',
        color: "#18181B",
        // fontWeight: 600,
        fontSize: "16px",
        backgroundColor: "#9AA3BA",
      }));
  return (
    <>
    {/* section fourth */}
    <div className="bg-bgdefault flex flex-col gap-12 items-center justify-center p-6 py-36">
          <span className="text-[43px] font-boldFont text-center w-2/5">
          Ready to Work on the forefront of Biotech? 
          </span>
          <span className="flex flex-row text-[#18181B] font-defaultFont px-auto justify-center items-center text-[22px] text-center max-w-1/3 w-1/3">
          Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
          </span>
          {/* <div className="flex flex-row p-3 rounded-md border-2 gap-8 focus:border-none w-2/6">
          <input type="text" className="bg-bgdefault text-white placeholder:text-dimWhite" placeholder="Enter Email Address" />
          <a
                type="button"
                href="/science"
                className="w-2/5 p-2 flex justify-center rounded-md bg-black text-center px-auto text-white"
              >
                Learn More
              </a> 
          </div> */}
          <div style={{width: 525, height: 72, position: 'relative'}}>
  <div style={{width: 525, height: 72, left: 0, top: 0, position: 'absolute', borderRadius: 10, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
    <div style={{alignSelf: 'stretch', flex: '1 1 0', paddingTop: 17, color: 'white', paddingBottom: 17, paddingLeft: 25, paddingRight: 16, background: '#9AA3BA', borderRadius: 15, overflow: 'hidden', border: '0.50px white solid', justifyContent: 'flex-start', alignItems: 'center', gap: 13, display: 'inline-flex'}}>
      <div style={{width: 227, color: 'white', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', lineHeight: 26, wordWrap: 'break-word'}}>Enter email address</div>
    </div>
  </div>
  <div style={{width: 197.43, height: 56, padding: 16, left: 318.75, top: 8, position: 'absolute', backgroundColor: '#18181B', borderRadius: 10, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
    <div style={{justifyContent: 'center', alignItems: 'center', gap: 9, display: 'inline-flex'}}>
      <div style={{textAlign: 'right', color: 'white', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', lineHeight: 24, wordWrap: 'break-word'}}>Learn More</div>
    </div>
  </div>
</div>
      </div>
    <Typography component={"div"} fullWidth sx={{
        padding: 4,
        // borderRadius: 3,
        backgroundColor: "#9AA3BA",
        bottom: 8,
        // marginTop: 5,
        // marginBottom: 3,
        width: '100%',
        // position: 'fixed'
        }} >
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
        {/* <Item> */}
        <img src={LogoImage} style={{ marginLeft: 4, width : "428px", height:"128px", marginRight: 5}}/>
        {/* </Item>  */}
        </Grid>
        <Grid item xs={6} md={2}>
        <Item>
          <a className='text-[#18181B] font-semiBoldFont' href={'/science'}>Science</a>
          </Item>  
        <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/science'}>About</a>
          </Item>  
        <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/science'}>Features</a>
          </Item>  
        <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/science'}>Work</a>
          </Item>  
        <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/science'}>Career</a>
          </Item>  
        </Grid>
        <Grid item xs={6} md={2}>
        <Item><a className='text-[#18181B] font-semiBoldFont' href={'/pipeline'}>Pipeline</a></Item> 
        <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/pipeline'}>Customer Support</a>
          </Item> 
          <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/pipeline'}>Delivery Details</a>
          </Item> 
          <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/pipeline'}>Terms & Conditions</a>
          </Item> 
          <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/pipeline'}>Privacy Policy</a>
          </Item> 
        </Grid>
        <Grid item xs={6} md={2}>
        <Item><a className='text-[#18181B] font-semiBoldFont' href={'/team'}>Team</a></Item> 
        <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/team'}>Founding Team</a>
          </Item> <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/team'}>Advisors</a>
          </Item> <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/team'}>Supporters</a>
          </Item> 
          
        </Grid>
        <Grid item xs={6} md={2}>
        <Item><a className='text-[#18181B] font-semiBoldFont' href={'/contact'}>Contact</a></Item> 
        <Item>
          <a className='text-[#18181B] font-defaultFont text-sm' href={'/contact'}>Contact form</a>
          </Item> 
        </Grid>
        </Grid>
        <Divider light sx={{color: "black", mt: 2}}/>
        <Typography component={"div"} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 2}}>
          <div>
            <p className='text-sm'>
            © Copyright 2023, All Rights Reserved by Giwotech
            </p>
          </div>
          <div className='flex flex-row gap-2'>
          {socialIcons.map((socialIcon, i) =>(
           <div className='flex justify-center bg-black rounded-full w-[32px] h-[32px] p-1 items-center text-bgdefault'> {React.createElement(socialIcon?.icon, { size: "20" })} </div>
          ))}
          </div>

        </Typography>
    </Typography>
    </>
  )
}

export default Footer
