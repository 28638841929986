import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import LogoOld from "./../assets/GiwoTech.png";
import Logo from "./../assets/giwotech_logo_new.png";
import Human from "./../assets/humanonly.png";
import { Link, useNavigate } from "react-router-dom";

const pages = [
  { name: "Home", path: "/home", hide: true },
  { name: "Science", path: "/science" },
  { name: "Pipeline", path: "/pipeline" },
  { name: "Team", path: "/team" },
  { name: "Contact", path: "/contact" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function HeaderGreen() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Typography variant="div" position="static" sx={{ backgroundColor: "#011B1B" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{}}>
          <Box
            variant="div"
            // noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#A7ECEC",
              textDecoration: "none",
              display: { xs: 'none', md: 'flex' }
            }}
          >
          <img src={Human} className="w-[50px] h-[50px] p-1 mt-2 -mr-3" />
            <img src={Logo} className="w-[180px] h-[62px]" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{color: "white"}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" onClick={() => navigate(page?.path)}>{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            // noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#A7ECEC",
              textDecoration: "none",
            }}
          >
            <img src={Human} className="w-[50px] h-[50px] p-1 mt-2 -mr-3" />
             <img src={Logo} className="w-[152px] h-[52px]" />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "right",
              gap: 3,
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Link className="my-2 block text-[16px] p-2 rounded-md text-[#A7ECEC] font-defaultFont font-medium hover:text-white"
                to={page.path}
              >
                {page.hide === true ? "" : page.name}
              </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </Typography>
  );
}
export default HeaderGreen;
